import "./App.css";
import Split from "react-split";
import AceEditor from "react-ace";
import { Editor } from "./components/editor";
import { useEffect, useRef, useState } from "react";
import Pyodide from "./components/pydoide";
import styles from "./components/editor.module.css";
// import { FileStructure } from './components/filestructure';
import { Fs } from "./components/tryonlinefilestructure";
import axios from "axios";
import ReactModal from "react-modal";

import { Turtle } from "./components/turtle";
// test
ReactModal.setAppElement("#root");
function App() {
  const [output, setOutput] = useState("output goes here....");
  const [subType, setSubType] = useState("");
  const [role, setRole] = useState("");
  const [teacherCopy, setTeacherCopy] = useState("");
  const [studentCopy, setStudentCopy] = useState("");
  const [studentActivityId, setStudentActivityId] = useState(null);

  const [isTeacherCopy, setISTeacherCopy] = useState(false);
  let onRun = useRef(null);
  let OnRunForModalOption = useRef(null);
  let forToHideMenuContext = useRef(null);
  const callOnRun = () => onRun.current();
  const hideMenuContext = () => forToHideMenuContext.current();

  const handleOutput = (val) => {
    setOutput(val);
  };

  const [program, setProgram] = useState("");

  const handlePythonProgram = (val) => {
    if (val) {
      setProgram(val);
    } else {
      setProgram("");
    }
  };

  useEffect(() => {
    AceEditor.value = "";
    AceEditor.value = program;
  }, [program]);

  const [wholeProjectData, setWholeProjectData] = useState([]);
  const [update, setUpdate] = useState(true);
  const [currentFileId, setCurrentFileId] = useState();
  const [projectID, setProjectID] = useState();

  const handleCurrentFileID = (id) => {
    setCurrentFileId(id);
  };

  const handleWholeProjectData = (data) => {
    setWholeProjectData([...data]);
  };

  const getCookie = (cname) => {
    // console.log(cname);
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    console.log(name, document.cookie);
    console.log("decodedCookie ", decodedCookie);
    //let decodedCookie = decodeURIComponent('user_id=63438; student_activity_id=44; coding_activity_id=1')
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  const [classTitle, setClassTitle] = useState("");
  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let user_id = params.get("user_id");
    let student_activity_id = params.get("student_activity_id");
    let homework_id = params.get("homework_id");
    let coding_activity_id = params.get("coding_activity_id");
    let coding_learning_outcome_id = params.get("coding_learning_outcome_id");
    let teacher_copy = params.get("teacher_copy");
    let student_copy = params.get("student_copy");
    console.log("setStudentActivityId", student_activity_id);
    setStudentActivityId(student_activity_id);
    setTeacherCopy(teacher_copy);
    setStudentCopy(student_copy);
    setISTeacherCopy(teacher_copy == "yes");
    let env = params.get("env");

    console.log("Checkerrrr", params.get("student_activity_id"));

    // console.log("user_idp", user_idp);
    // console.log("student_activity_id", student_activity_id);
    // console.log("coding_activity_idp", coding_activity_idp);

    // let user_id = 63438
    // let student_activity_id = 1
    // let coding_activity_id = 1
    // let user_id = user_idp
    // let student_activity_id = student_activity_idp
    // let coding_activity_id = coding_activity_idp

    //let user_id= getCookie("user_id");
    // let student_activity_id= getCookie("student_activity_id")
    // let coding_activity_id= getCookie("coding_activity_id")
    if (user_id == undefined || user_id == null) {
      user_id = getCookie("user_id");
    }
    console.log("user_id", getCookie("user_id"), user_id);
    // console.log("student_activity_id",getCookie("student_activity_id"));
    // console.log("coding_activity_id",getCookie("coding_activity_id"));
    var link = "";

    if (student_activity_id == null) {
      if (env == "test") {
        link = `https://testserver.begalileo.com/access_codings/get_project?user_id=${user_id}&coding_activity_id=${coding_activity_id}&coding_learning_outcome_id=${coding_learning_outcome_id}&teacher_copy=${teacher_copy}&student_copy=${student_copy}&homework_id=${
          homework_id || ""
        }`;
      } else if (env == "staging") {
        link = `https://testbg.begalileo.com/access_codings/get_project?user_id=${user_id}&coding_activity_id=${coding_activity_id}&coding_learning_outcome_id=${coding_learning_outcome_id}&teacher_copy=${teacher_copy}&student_copy=${student_copy}&homework_id=${
          homework_id || ""
        }`;
      } else {
        link = `https://learn.begalileo.com/access_codings/get_project?user_id=${user_id}&coding_activity_id=${coding_activity_id}&coding_learning_outcome_id=${coding_learning_outcome_id}&teacher_copy=${teacher_copy}&student_copy=${student_copy}&homework_id=${
          homework_id || ""
        }`;
      }
    } else {
      if (env == "test") {
        link = `https://testserver.begalileo.com/access_codings/get_project?user_id=${user_id}&student_activity_id=${student_activity_id}&coding_activity_id=${coding_activity_id}&coding_learning_outcome_id=${coding_learning_outcome_id}&teacher_copy=${teacher_copy}&student_copy=${student_copy}&homework_id=${
          homework_id || ""
        }`;
      } else if (env == "staging") {
        link = `https://testbg.begalileo.com/access_codings/get_project?user_id=${user_id}&student_activity_id=${student_activity_id}&coding_activity_id=${coding_activity_id}&coding_learning_outcome_id=${coding_learning_outcome_id}&teacher_copy=${teacher_copy}&student_copy=${student_copy}&homework_id=${
          homework_id || ""
        }`;
      } else {
        link = `https://learn.begalileo.com/access_codings/get_project?user_id=${user_id}&student_activity_id=${student_activity_id}&coding_activity_id=${coding_activity_id}&coding_learning_outcome_id=${coding_learning_outcome_id}&teacher_copy=${teacher_copy}&student_copy=${student_copy}&homework_id=${
          homework_id || ""
        }`;
      }
    }
    async function fetchData() {
      await axios
        .post(link)
        .then((res) => {
          console.log(res.data, "response");
          if (res.data.project_data == undefined) {
            alert(res.data.message);
            setTimeout(() => {
              window.location.href = "https://learn.begalileo.com/";
            }, 3000);
          }
          setWholeProjectData([...res.data.project_data]);
          setProjectID(res.data.coding_learning_outcome_id);
          setSubType(res.data.sub_type);
          setRole(res.data.role);
          setClassTitle(res.data.class_title);
          //setRole("teacher")
          //setSubType('turtle')
          if (subType == "turtle") {
            setIspy(false);
          } else {
            setIspy(true);
          }

          // if (res.data.project_type != 'python') {
          //   console.log(res.data.project_type);
          //   alert("This is not a python project")
          //   setTimeout(()=>{
          //     window.location.href = "https://learn.begalileo.com/"
          //   },3000)
          //   //window.location.href = "https://learn.begalileo.com/"
          // }
        })
        .catch((err) => {
          console.log("Error while get project", err);
          // alert(err)
          // setTimeout(()=>{
          //   window.location.href = "https://learn.begalileo.com/"
          // },3000)
          //window.location.href = "https://learn.begalileo.com/"
        });
    }
    fetchData();
    console.log("Version Latest 1");
  }, [update]);

  useEffect(() => {
    console.log("In usee", wholeProjectData);
    if (tabFiles.length === 0) {
      // console.log("No files")
      let data = wholeProjectData.filter((e) => (e.name === "main.py" ? 1 : 0));
      //console.log(data[0]);
      if (data[0]) {
        let obj = {
          fileName: data[0].name,
          id: data[0].file_id,
          code: data[0].code,
          isActive: true,
        };
        //console.log(obj);
        setCurrentFileId(obj.id);
        setTabFiles([...tabFiles, obj]);
        handlePythonProgram(obj.code);
      }
    }
  }, [wholeProjectData]);

  const [save, setSave] = useState(false);
  const handleSave = () => {
    if (save) {
      setSave(false);
    } else {
      setSave(true);
    }
  };

  useEffect(() => {
    console.log("heyyyyy!", currentFileId);
    async function save() {
      if (currentFileId) {
        let updatedData = wholeProjectData.map((e) => {
          if (currentFileId === e.file_id) {
            e.code = AceEditor.value;
            console.log("FileName", e.name);
          }
          return e;
        });
        console.log("", currentFileId, AceEditor.value);
        setWholeProjectData([...updatedData]);
        // updatedData = encodeURIComponent(JSON.stringify(updatedData));
        // await axios.post(`https://testserver.begalileo.com/access_codings/update_project?user_id=63438&student_activity_id=44&coding_activity_id=1&project_data=${updatedData}`)
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let user_id = params.get("user_id");
        let student_activity_id = params.get("student_activity_id");
        let coding_activity_id = params.get("coding_activity_id");
        let homework_id = params.get("homework_id");
        let coding_learning_outcome_id = params.get(
          "coding_learning_outcome_id"
        );
        let env = params.get("env");
        let url = "";

        console.log("env in updating", env);
        if (env == "staging") {
          url = " https://testbg.begalileo.com/access_codings/update_project";
        } else if (env == "test") {
          url =
            "https://testserver.begalileo.com/access_codings/update_project";
        } else {
          url = "https://learn.begalileo.com/access_codings/update_project";
        }

        var formdata = new FormData();

        formdata.append("project_data", JSON.stringify(updatedData));
        formdata.append("user_id", user_id);
        if (student_activity_id != null) {
          formdata.append("student_activity_id", student_activity_id);
        }
        formdata.append("coding_activity_id", coding_activity_id);
        formdata.append(
          "coding_learning_outcome_id",
          coding_learning_outcome_id
        );
        formdata.append("homework_id", homework_id);
        formdata.append("teacher_copy", teacherCopy);
        formdata.append("student_copy", studentCopy);

        await axios({
          method: "post",
          url: url,
          data: formdata,
          headers: { "Content-Type": "application/json" },
        }).then(
          (response) => {
            console.log({ response });
            console.log("Saved");
          },
          (error) => {
            console.log(error);
          }
        );

        document.getElementById("aft").style.display = "flex";
        setTimeout(() => {
          document.getElementById("aft").style.display = "none";
        }, 3000);

        handlePythonProgram(AceEditor.value);
        //setCurrentFileId()
        setUpdate(!update);
      }
    }

    save();
  }, [save]);

  const handleUpdate = (val) => {
    setUpdate(val);
  };

  //check
  const [tabFiles, setTabFiles] = useState([]);

  const handleTabfiles = (data) => {
    if (tabFiles.length) {
      let updatedData = [...tabFiles];
      for (let i = 0; i < data.length; i++) {
        let temp = updatedData.filter((e) =>
          Number(e.id) === Number(data[i].file_id) ? 0 : 1
        );
        updatedData = [...temp];
      }
      updatedData[updatedData.length - 1].isActive = true;
      handlePythonProgram(updatedData[updatedData.length - 1].code);
      setCurrentFileId(updatedData[updatedData.length - 1].id);
      setTabFiles([...updatedData]);
    }
  };

  const handleTabsOnRemove = (file_id) => {
    let isAactiveTabClosing = false;
    let index = 0;
    let temp;
    let updatedArr = tabFiles.filter((e, i) => {
      if (e.id !== file_id) {
        return 1;
      } else {
        if (e.isActive) {
          isAactiveTabClosing = true;
          index = i;
        }
        return 0;
      }
    });
    if (isAactiveTabClosing && updatedArr.length >= 1) {
      if (index === 0) {
        temp = index;
        //index = index;
      } else {
        temp = index - 1;
        //index = index - 1;
      }

      index = temp;
      updatedArr[index].isActive = true;
      handlePythonProgram(updatedArr[index].code);
      setCurrentFileId(updatedArr[index].file_id);
    } else {
      if (updatedArr.length === 0) {
        handlePythonProgram("");
      }
    }
    setTabFiles([...updatedArr]);
  };

  const handleFilesToAddIntoTabFilesList = (data) => {
    let arrOfObj = tabFiles;
    let flag = arrOfObj.filter((e) => (e.id === data.file_id ? 1 : 0));
    let obj = {
      fileName: data.name,
      id: data.file_id,
      code: data.code,
      isActive: true,
    };
    if (flag.length === 0) {
      let updatedArr = tabFiles.map((e) => {
        return { ...e, isActive: false };
      });
      setTabFiles([...updatedArr, obj]);
    } else {
      let updatedArr = arrOfObj.filter((e) => (e.id !== data.file_id ? 1 : 0));
      updatedArr = updatedArr.map((e) => {
        return { ...e, isActive: false };
      });
      setTabFiles([...updatedArr, obj]);
    }
    //console.log("In Add into tabs list", obj.code);
    handlePythonProgram(obj.code);
    setCurrentFileId(obj.id);
  };

  const handleOnClickOnTabFile = (data, i) => {
    let obj = data;
    obj.isActive = true;
    // console.log(obj);

    let updatedArr = tabFiles.map((e) => {
      return { ...e, isActive: false };
    });
    updatedArr[i] = obj;
    setTabFiles([...updatedArr]);
    handlePythonProgram(obj.code);
    setCurrentFileId(obj.id);
  };

  const [ispy, setIspy] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleIsLoading = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (subType !== "turtle") {
      setIspy(true);
      setIsLoading(true);
    } else {
      setIspy(false);
      setIsLoading(false);
    }
    console.log(ispy);
    // setIspy(true)
    // setIsLoading(true)
  }, [subType]);

  const handleRunOption = (pid) => {
    let code = wholeProjectData.filter((e) => (e.file_id == pid ? 1 : 0));
    if (ispy) {
      OnRunForModalOption.current(code[0].code);
    } else {
      window.turtleProgram = code[0].code;
      window.runit();
    }
  };

  return (
    <div
      className="App"
      onClick={() => {
        hideMenuContext();
      }}
    >
      {/* <h1>hey!</h1> */}
      <div
        style={{
          height: "50px",
          background: "white",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ paddingLeft: ".8%", background: "white" }}>
          <img
            src="https://learn.begalileo.com/assets/mathbox/media/logo.svg"
            alt="logo"
            style={{ height: "40px", objectFit: "contain" }}
          />
        </div>
        <div style={{ paddingLeft: "40%", color: "rgba(62, 70, 255, 1)" }}>
          <h3>{classTitle}</h3>
        </div>
      </div>
      <div className={styles.editorStructure}>
        <div className={styles.file}>
          <Fs
            wholeProjectData={wholeProjectData}
            handleUpdate={handleUpdate}
            update={update}
            handlePythonProgram={handlePythonProgram}
            handleCurrentFileID={handleCurrentFileID}
            handleFilesToAddIntoTabFilesList={handleFilesToAddIntoTabFilesList}
            handleWholeProjectData={handleWholeProjectData}
            projectID={projectID}
            forToHideMenuContext={forToHideMenuContext}
            handleTabsOnRemove={handleTabsOnRemove}
            handleTabfiles={handleTabfiles}
            role={role}
            handleRunOption={handleRunOption}
            studentCopy={studentCopy}
            teacherCopy={teacherCopy}
          />
        </div>
        <ReactModal
          isOpen={isLoading}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.9)",
              zIndex: 101,
            },
            content: {
              height: "fit-content",
              boxSizing: "border-box",
              width: "20%",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              overflow: "hidden",
              padding: "0px 10px 0px 10px",
            },
          }}
        >
          {" "}
          <h5>Loading....</h5>{" "}
        </ReactModal>
        <div className={styles.pythoneditor}>
          <div id={styles.pythoneditorLeft}>
            <div id={styles.top}>
              <div className={styles.Tabs}>
                {tabFiles.map((e, i) => {
                  return (
                    <div
                      key={i}
                      className={styles.Tab}
                      style={{
                        backgroundColor:
                          e.isActive === true ? "white" : "#B6FFCE",
                      }}
                      onClick={(event) => {
                        event.preventDefault();
                        handleOnClickOnTabFile(e, i);
                      }}
                    >
                      <span>{e.fileName}</span>
                      <i
                        className={`fa fa-times ${styles.cross}`}
                        aria-hidden="true"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleTabsOnRemove(e.id);
                        }}
                      ></i>
                    </div>
                  );
                })}
              </div>
              <div>
                {/* { (role === "teacher" && isTeacherCopy == true ) ? <></>: */}
                {role === "teacher" &&
                studentCopy == "yes" &&
                studentActivityId == null ? (
                  <></>
                ) : (
                  <button
                    className={styles.run}
                    onClick={() => {
                      console.log("going to save");
                      console.log(AceEditor.value);
                      setSave(!save);
                    }}
                  >
                    <i className="fa fa-floppy-o" aria-hidden="true"></i>
                    <span style={{ marginLeft: "3px" }}>Save</span>
                  </button>
                )}
              </div>
            </div>
            <div id={styles.bottom}>
              {tabFiles.length > 0 ? (
                <Editor
                  program={program}
                  handleSave={handleSave}
                  role={role}
                  teacherCopy={isTeacherCopy}
                  studentActivityId={studentActivityId}
                />
              ) : (
                <>
                  <h1>Please open any file to show the editor</h1>
                </>
              )}
            </div>
          </div>

          <div id={styles.gap}></div>

          <div id={styles.pythoneditorRight}>
            <div id={styles.top}>
              <div></div>
              <div>
                {/* <i className="fa fa-play"></i>
                <i className="fa-duotone fa-play"></i>
                <i class="fa fa-play" aria-hidden="true"></i> */}
                <button
                  className={`${styles.run}`}
                  onClick={() => {
                    if (ispy) {
                      callOnRun();
                    } else {
                      window.turtleProgram = program;
                      console.log(program);
                      window.runit();
                    }
                  }}
                >
                  <i className="fa fa-play"></i>
                  <span style={{ marginLeft: "3px" }}>Run</span>
                </button>
                <button
                  className={`${styles.clear}`}
                  onClick={() => {
                    if (ispy) {
                      handleOutput("");
                    } else {
                      window.clearOutput();
                    }
                  }}
                >
                  <i className="fa fa-eraser" aria-hidden="true"></i>
                  <span style={{ marginLeft: "2px" }}>Clear</span>
                </button>
              </div>
            </div>
            <div id={styles.bottom}>
              {/* <Pyodide pythonCode={AceEditor.value} output={output} handleOutput={handleOutput} onRun={onRun} handleIsLoading={handleIsLoading} /> */}
              {/* <Split  style={{ height: "100vh", width: "100%" }} direction="vertical" sizes={[25, 75]}
                minSize={50}
                expandToMin={false}
                gutterSize={10}
                gutterAlign="center"
                snapOffset={30}
                dragInterval={1}
                cursor="row-resize">
                  <Pyodide pythonCode={AceEditor.value} output={output} handleOutput={handleOutput} onRun={onRun} handleIsLoading={handleIsLoading} />
                  <Turtle/>
              </Split> */}
              {ispy ? (
                <Pyodide
                  pythonCode={""}
                  output={output}
                  handleOutput={handleOutput}
                  onRun={onRun}
                  OnRunForModalOption={OnRunForModalOption}
                  handleIsLoading={handleIsLoading}
                />
              ) : (
                <Turtle />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <Turtle/> */}

      {/* <Fs wholeProjectData={wholeProjectData} handleUpdate={handleUpdate} update={update} /> */}
      <div id="aft" className={styles.aftersaving}>
        Changes Saved
      </div>
    </div>
  );
}

{
  /* <div style={{ height: "50%", width: "100%" }}>
                  <Pyodide pythonCode={AceEditor.value} output={output} handleOutput={handleOutput} onRun={onRun} handleIsLoading={handleIsLoading} />
                </div>
                <div style={{ border: "1px solid black", height: "50%" }}> Hey</div> */
}

export default App;
