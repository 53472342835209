import axios from "axios";
import { useState } from "react";
import styles from "./buttonStyles.module.css";
export const UploadModal = ({
  projectID,
  wholeProjectData,
  handleOpenModal,
  setModalIsOpen,
  handleUpdate,
  pid,
  update,
}) => {
  const [selectedFile, setSelectedFile] = useState();
  const [fileData, setFileData] = useState("initial");

  const onFileChange = (event) => {
    //  console.log(event.target.files[0].name);
    setSelectedFile(event.target.files[0]);

    let reader = new FileReader();
    reader.onload = function (e) {
      console.log(e.target.result);
      setFileData({ file: reader.result });
    };
    reader.readAsText(event.target.files[0]);
  };

  async function uploadFile() {
    var PythonFileExtensionCheck = /^[^.]+.py$/;

    if (PythonFileExtensionCheck.test(selectedFile.name) === false) {
      alert("Sorry!, you choosed a wrong extension");
      return;
    }
    let file_id = Math.floor(Math.random() * 10000 + 1000);
    let code = fileData.file;
    let newdata = {
      file_id: file_id,
      project_id: projectID,
      type: "file",
      parent: pid === 0 ? null : pid,
      parents: null,
      name: selectedFile.name,
      path: "dkfkmd",
      code: "",
    };
    console.log("in Upload.......", newdata);
    console.log("in Upload.......", typeof fileData.file);
    newdata.code = code;
    //newdata = JSON.stringify(newdata)

    //await axios.post("http://localhost:3001/files", newdata)
    // await axios.patch(`http://localhost:3001/temp/${8}`,{"content": fileData.file} )
    let updatedData = [...wholeProjectData, newdata];
    //updatedData = encodeURIComponent(JSON.stringify(updatedData))
    //updatedData = JSON.stringify(updatedData)
    console.log("in Upload.......", updatedData);
    //await axios.post(`https://testserver.begalileo.com/access_codings/update_project?user_id=63438&student_activity_id=44&coding_activity_id=1&project_data=${updatedData}`)
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let user_id = params.get("user_id");
    let env = params.get("env");
    let student_activity_id = params.get("student_activity_id");
    let coding_activity_id = params.get("coding_activity_id");
    let teacher_copy = params.get("teacher_copy");
    let student_copy = params.get("student_copy");
    let homework_id = params.get("homework_id");
    let coding_learning_outcome_id = params.get("coding_learning_outcome_id");

    var formdata = new FormData();

    formdata.append("project_data", JSON.stringify(updatedData));
    formdata.append("user_id", user_id);
    if (student_activity_id != null) {
      formdata.append("student_activity_id", student_activity_id);
    }
    formdata.append("coding_learning_outcome_id", coding_learning_outcome_id);
    formdata.append("homework_id", homework_id);
    formdata.append("coding_activity_id", coding_activity_id);
    console.log("formdata.get(homework_id)", formdata.get("homework_id"));
    var link;
    if (env == "test") {
      link = `https://testserver.begalileo.com/access_codings/update_project?user_id=${user_id}&coding_activity_id=${coding_activity_id}&coding_learning_outcome_id=${coding_learning_outcome_id}&teacher_copy=${teacher_copy}&student_copy=${student_copy}&homework_id=${
        homework_id || ""
      }`;
    } else if (env == "staging") {
      link = `https://testbg.begalileo.com/access_codings/update_project?user_id=${user_id}&coding_activity_id=${coding_activity_id}&coding_learning_outcome_id=${coding_learning_outcome_id}&teacher_copy=${teacher_copy}&student_copy=${student_copy}&homework_id=${
        homework_id || ""
      }`;
    } else {
      link = `https://learn.begalileo.com/access_codings/update_project?user_id=${user_id}&coding_activity_id=${coding_activity_id}&coding_learning_outcome_id=${coding_learning_outcome_id}&teacher_copy=${teacher_copy}&student_copy=${student_copy}&homework_id=${
        homework_id || ""
      }`;
    }
    console.log({ link });
    await axios({
      method: "post",

      //   url: "https://learn.begalileo.com/access_codings/update_project",
      url: link,
      data: formdata,
      headers: { "Content-Type": "application/json" },
    }).then(
      (response) => {
        console.log({ response });
      },
      (error) => {
        console.log(error);
      }
    );

    handleUpdate(!update);
  }

  return (
    <>
      <h2>Upload file</h2>
      <input
        type="file"
        accept=".py"
        onChange={(e) => {
          onFileChange(e);
        }}
      ></input>
      <div
        style={{ display: "flex", margin: "15px 0px", justifyContent: "end" }}
      >
        <button
          style={{ marginRight: "10px" }}
          className={styles.cancle}
          onClick={() => {
            setModalIsOpen(false);
            handleOpenModal(false);
          }}
        >
          Cancle
        </button>
        <button
          className={styles.sucess}
          onClick={() => {
            handleOpenModal(false);
            setModalIsOpen(false);
            uploadFile();
          }}
        >
          Upload
        </button>
      </div>
    </>
  );
};
